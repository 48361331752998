<template>
  <page-loader v-if="loading" />
  <anonymous-page-layout v-else>
    <div v-if="target">
      Vous avez été invité à rejoindre l'espace <b>{{ target.name }}</b>
      <span v-if="target.establishment"> de l'établissement <b>{{ target.establishment.name }}</b></span>.

      <div>
        <template v-if="$auth.user">
          <v-btn color="secondary" small :disabled="accepting || declining" :loading="accepting"
                 @click="accept"
          >
            Rejoindre l'espace
          </v-btn>
          <v-btn color="white" small :disabled="accepting || declining" :loading="declining" class="ml-2"
                 @click="decline"
          >
            Refuser l'invitation
          </v-btn>
        </template>
        <template v-else>
          <v-btn color="secondary" small :disabled="declining" @click="login">Se connecter / Créer un compte</v-btn>
          <v-btn color="white" small :disabled="declining" :loading="declining" class="ml-2"
                 @click="decline"
          >
            Refuser l'invitation
          </v-btn>
        </template>
      </div>
    </div>
    <div v-else>{{ error||'Une erreur est survenue.' }} Vous allez être redirigé...</div>
  </anonymous-page-layout>
</template>

<script>
  import {acceptLink, declineLink, getLink} from '@/modules/invitations/api'
  import PageLoader from '@/modules/core/layout/PageLoader'
  import {redirect} from '@/services/space'
  import AnonymousPageLayout from '@/modules/core/layout/AnonymousPageLayout'

  export default {
    name: 'SpaceJoin',
    components: {AnonymousPageLayout, PageLoader},
    async beforeRouteEnter(to, from, next) {
      let invitationLink, target, error
      try {
        let {data} = await getLink(to.params.token)

        if (data.target.type !== 'space') {
          error = 'Le lien d\'invitation est invalide.'
        } else {
          invitationLink = data.invitation_link
          target = data.target
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        error = e.response.status === 404 ? 'Le lien d\'invitation est invalide.' : (e.response && e.response.data ? e.response.data.title : null)
      }

      next(vm => {
        vm.invitationLink = invitationLink
        vm.target = target
        vm.error = error
      })
    },
    props: {
      token: String
    },
    data() {
      return {
        loading: false,
        invitationLink: null,
        target: null,
        error: null,
        accepting: false,
        declining: false
      }
    },
    mounted() {
      // In mounted because beforeRouteEnter is called between created and mounted hooks
      if (this.error) {
        setTimeout(async () => {
          try {
            await this.$router.push('/')
          } catch (e) {
            // ignore it
          }
        }, 5000)
      }
    },
    methods: {
      login() {
        this.$router.push({name: 'welcome', params: {target: this.$router.currentRoute.fullPath}})
      },
      async accept() {
        try {
          this.accepting = true
          await acceptLink(this.token)
          redirect(this.target.slug)
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.accepting = false
        }
      },
      async decline() {
        try {
          this.declining = true
          await declineLink(this.token)
        } finally {
          this.declining = false

          try {
            await this.$router.push('/')
          } catch (e) {
            // ignore it
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
